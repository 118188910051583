import React from "react";
import "./Blog.jsx";

import banner from "../../assets/banner_img1.jpg";
export default function Blog() {
  return (
    <div className="blog-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Blog</div>
      </div>
    </div>
  );
}
