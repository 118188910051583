import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import logo from "../../assets/dekat_logo.png";
import "./Navbar.scss";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`container navbar ${scrolled ? "dark-nav" : ""}`}>
      <Link to="/" className="navbar-left">
        <img src={logo} alt="DEKAT LOGO" />
        <p>DEKAT ENGINEERING</p>
      </Link>
      <div className="navbar-right">
        <button className="menu-toggle" onClick={toggleMenu}>
          <FaBars color="#fff" />
        </button>

        <ul className={`nav-links ${showMenu ? "active" : ""}`}>
          <li>
            <NavLink exact to="/" onClick={toggleMenu}>
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" onClick={toggleMenu}>
              ABOUT US
            </NavLink>
          </li>
          <li>
            <NavLink to="/services" onClick={toggleMenu}>
              SERVICES
            </NavLink>
          </li>
          <li>
            <NavLink to="/career" onClick={toggleMenu}>
              CAREER
            </NavLink>
          </li>
          <li>
            <NavLink to="/forms" onClick={toggleMenu}>
              FORMS
            </NavLink>
          </li>
          <li>
            <NavLink to="/team" onClick={toggleMenu}>
              TEAM
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" onClick={toggleMenu}>
              CONTACT US
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
