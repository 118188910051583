import React, { useState, useEffect } from "react";
import "./Forms.scss";
import banner from "../../assets/banner_img1.jpg";

const forms = [
  {
    id: "tab1",
    src: "https://form.jotform.com/233446071104143",
    title: "New Project",
  },
  {
    id: "tab2",
    src: "https://form.jotform.com/233446165125150",
    title: "New Customer Registration Form",
  },
  {
    id: "tab3",
    src: "https://form.jotform.com/233467054606154",
    title: "Project Update Request",
  },
];

export default function Forms() {
  const [activeTab, setActiveTab] = useState(forms[0].id);

  const [iframeHeight, setIframeHeight] = useState("539px"); // Initial height

  useEffect(() => {
    const handleIFrameMessage = (e) => {
      if (typeof e.data !== "string") return; // Ensure string data

      var args = e.data.split(":");
      // Only process messages intended for the iframe. Adjust according to your needs.
      if (args.length > 2 && args[0] === "setHeight") {
        const height = args[1];
        setIframeHeight(height + "px"); // Update state to adjust height
      }
      // Add other cases as needed
    };

    window.addEventListener("message", handleIFrameMessage);
    return () => window.removeEventListener("message", handleIFrameMessage);
  }, []);

  return (
    <div className="form-container">
      <div className="banner-container">
        <img src={banner} alt="Forms Banner" />
        <div className="banner-text">Forms</div>
      </div>

      <div className="tabs-container">
        {forms.map((form) => (
          <div
            key={form.id}
            className={`tab ${activeTab === form.id ? "active" : ""}`}
            onClick={() => setActiveTab(form.id)}
          >
            {form.title}
          </div>
        ))}
      </div>

      <div className="tab-content">
        {forms.map(
          (form) =>
            activeTab === form.id && (
              <iframe
                key={form.id}
                id={`JotFormIFrame-${form.id}`}
                title={form.title}
                // onload="window.parent.scrollTo(0,0)"
                allowTransparency="true"
                allowFullScreen="true"
                allow="geolocation; microphone; camera"
                src={form.src}
                style={{
                  minWidth: "100%",
                  maxWidth: "100%",
                  height: iframeHeight,
                  border: "none",
                }}
              ></iframe>
            )
        )}
      </div>
    </div>
  );
}
