// ServiceItem.jsx
import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const ServiceItem = ({ service, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Adjusted itemVariants to include delay based on index
  const itemVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10,
        delay: index * 0.2, // Delay each item based on its index
      },
    },
  };

  return (
    <motion.div
      className="service"
      ref={ref}
      variants={itemVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      <div
        className="service-image"
        style={{ backgroundImage: `url(${service.image})` }}
      >
        <div className="service-overlay">
          <div className="service-number">{`0${service.id}`}</div>
          <div className="service-info">
            <h3>{service.title}</h3>
            <a href="/services" className="contact-link">
              See More Services
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ServiceItem;
