import React, { useEffect, useState } from "react";
import "./Career.scss";
import banner from "../../assets/banner_img1.jpg";
import career from "../../assets/dekat-career.jpg";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Career = () => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchJobs = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/jobs`
        );
        const data = await response.json();

        // Ensure that 'data' is an array
        if (Array.isArray(data)) {
          setJobs(data);
        } else {
          console.error("Unexpected data format:", data);
          setJobs([]); // Set jobs as an empty array if data is not an array
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
        setJobs([]); // Handle the error by setting jobs to an empty array
      }
    };

    fetchJobs();
  }, []);

  const handleButtonClick = (jobId) => {
    navigate(`/jobs/${jobId}`); // Navigate to the job details page
  };

  return (
    <div className="career-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Career</div>
      </div>
      <div className="image-career">
        <img src={career} alt="Career"></img>
      </div>

      <div className="current-opening">
        <h2>Current Openings</h2>
        <p className="opening-description">
          We are hiring! Check out our latest job opportunities below.
        </p>
      </div>
      <div className="job-box">
        {jobs.length === 0 ? (
          <h1 className="no-openings">There are no current job openings.</h1>
        ) : (
          jobs.map((job) => (
            <div className="job-item" key={job.id}>
              <div className="job-item-header">
                <div className="job-icon">
                  <FontAwesomeIcon icon={faBriefcase} className="icon" />
                </div>
                <div className="job-info">
                  <h3 className="job-title">{job.title}</h3>
                  <p className="job-location">{job.location}</p>
                </div>
                <div className="apply-now">
                  <button onClick={() => handleButtonClick(job.id)}>
                    APPLY NOW{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="arrow-icon"
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Career;
