import React, { useEffect, useRef } from "react";
import "./Services.scss"; // Import your SCSS file here
import image1 from "../../assets/building_design.jpg";
import image2 from "../../assets/speciality_service.jpg";
import image3 from "../../assets/outdoor_services.jpg";
import image4 from "../../assets/temporary_service.jpg";
import image5 from "../../assets/founcation_services.jpg";
import image6 from "../../assets/cad_services.png";
import banner from "../../assets/banner_img1.jpg";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Services = () => {
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">Services</div>
      </div>
      <h1 className="services-heading">Structural Design</h1>
      <div className="services">
        <motion.div
          className="service-box"
          // initial={{ opacity: 0 }}
          // whileInView={{ opacity: 1 }}
          // viewport={{ root: scrollRef }}
          // animate={{ x: 100 }}
          // transition={{
          //   ease: "linear",
          //   duration: 2,
          //   x: { duration: 1 },
          // }}
        >
          <img src={image1} alt="Service Image 1" />
          <div className="service-content">
            <h3>Building Design</h3>
            <p>
              Large-scale residential, Commercial, Custom Homes, and Consulting
            </p>
            <p>
              Our firm is dedicated to offering top-tier structural engineering
              consulting services. We provide invaluable expertise to ensure
              structural integrity and safety, alongside meticulously planned
              cost-effective designs. Our approach to delivery stands out with
              its collaborative and project-focused nature, fostering a deeply
              engaging design journey. By partnering closely with your team, we
              ensure the structural elements harmonize seamlessly with your
              architectural vision, enhancing both the aesthetic allure and the
              structural robustness of your designs.
              <ul>
                <li>New construction in all materials</li>
                <li> Structural Assessment & Feasibility Studies</li>
                <li>Design Assist & Connection Design</li>
                <li>Peer Review Services</li>
              </ul>
            </p>
          </div>
        </motion.div>
        <div className="service-box alternate">
          <img src={image4} alt="Service Image 4" />
          <div className="service-content">
            <h3>TEMPORARY WORKS</h3>
            <p>
              Temporary works, aka Construction Engineering Services, play a
              crucial role in supporting the construction process by providing
              temporary structures, systems, and solutions necessary for the
              safe and efficient execution of construction projects.
            </p>
            <ul>
              <li>Formwork</li>
              <li>Falsework</li>
              <li>Temporary condition of permanent structures </li>
              <li>Shoring</li>
            </ul>
          </div>
        </div>
        <div className="service-box ">
          <img src={image2} alt="Service Image 2" />
          <div className="service-content">
            <h3>SPECIALTY ENGINEERING</h3>
            <p>
              DEKAT Engineering is a full-service consulting and structural
              engineering firm. This includes analysis for most Delegated Design
              and/or Deferred Submittal specialty engineering requirements.
            </p>

            <ul>
              <li>Steel Stairs</li>
              <li>Solar Support Engineering</li>
              <li>Deferred Submittal & Delegated Design</li>
            </ul>
          </div>
        </div>
        <div className="service-box alternate">
          <img src={image5} alt="Service Image 5" />
          <div className="service-content">
            <h3>FOUNDATION DESIGN</h3>
            <p>
              A solid foundation is the bedrock of any safe structure. At DEKAT,
              we specialize in delivering tailored foundation solutions that
              ensure the stability and longevity of your project through a keen
              eye for detail and a thorough understanding of soil mechanics.
              Trust DEKAT To lay the groundwork for your project.{" "}
            </p>
            <ul>
              <li>PEMB foundation</li>
              <li>Fabric buildings & shelters foundation</li>
              <li>Ground-mount solar foundation</li>
            </ul>
          </div>
        </div>
        <div className="service-box">
          <img src={image3} alt="Service Image 3" />
          <div className="service-content">
            <h3>OUTDOOR LIVING PROJECTS DECK, PATIO COVER, PERGOLA, ET. AL.</h3>
            <p>
              Our journey at DEKAT began with a deck design, marking our
              inaugural project as a company. We take immense pride in our role
              as partner to builders and general contractors, providing
              essential structural engineering designs for decks, patio covers,
              pergolas, and gazebos.
              <p>
                From the initial deck to the myriad structures we have designed
                since, our commitment remains steadfast: to deliver structurally
                sound structures.
              </p>
            </p>
          </div>
        </div>
        <div className="service-box alternate">
          <img src={image6} alt="Service Image 5" />
          <div className="service-content">
            <h3>CAD and Drafting</h3>
            <p>
              Experience precision and efficiency with our CAD & Drafting
              services. Our expert team utilizes cutting-edge software to
              deliver detailed and accurate designs tailored to your needs. From
              2D drafting to 3D modeling, we ensure exceptional results, on time
              and within budget. Partner with us for quality that exceeds
              expectations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
