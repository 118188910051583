import React, { useEffect } from "react";
import Banner from "./Banner/Banner";
import Statemap from "./Statemap/Statemap";
import CTA from "./CTA/CTA";
import Services from "./Services/Services";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Banner />
      <CTA />
      <Statemap />
      <Services />
    </div>
  );
};

export default Home;
