// Services.jsx
import React from "react";
import ServiceItem from "./ServiceItem"; // Adjust the path as necessary
import building from "../../../assets/building_design.jpg";
import temporary from "../../../assets/temporary_service.jpg";
import specialty from "../../../assets/speciality_service.jpg";
import outdoor from "../../../assets/outdoor_services.jpg";
import "./Services.scss";

const Services = () => {
  const services = [
    { id: 1, title: "Building Design", image: building },
    { id: 2, title: "Temporary Works", image: temporary },
    { id: 3, title: "Specialty Engineering", image: specialty },
    { id: 4, title: "Outdoor Living Projects", image: outdoor },
  ];

  return (
    <div className="services-container">
      <div className="services-wrapper">
        {services.map((service) => (
          <ServiceItem key={service.id} service={service} />
        ))}
      </div>
    </div>
  );
};

export default Services;
