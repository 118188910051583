import React from "react";
import "./Banner.scss";
import "../Home.scss";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div className="hero container">
      {/* Use motion.div instead of div for the container you want to animate */}
      <motion.div
        className="hero-text"
        initial={{ opacity: 0, y: -20 }} // Start with 0 opacity and slightly above its final position
        animate={{ opacity: 1, y: 0 }} // Animate to full opacity and its final position
        transition={{ duration: 0.8, ease: "easeOut" }} // Control the duration and easing of the animation
      >
        <h1>Welcome to</h1>
        <h1 className="company-name">DEKAT ENGINEERING</h1>
        <p className="shared-text-style">
          DEKAT Engineering is a Rocky Mountain based company founded in 2022
          and specializes in providing structural engineering and consulting
          services. At DEKAT, we strive to meet our client's needs in the most
          effective, practical, and timely way possible, all while upholding
          unparalleled quality standards.
        </p>

        {/* Your commented-out div can also be animated similarly if needed */}
      </motion.div>
    </div>
  );
};

export default Banner;
