import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/dekat_logo.png";
import facebookLogo from "../../assets/facebook.svg";
import youtubeLogo from "../../assets/youtube.png";
import linkedinLogo from "../../assets/linkedin.png";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} alt="DEKAT ENGINEERING" className="footer-logo" />
          <p className="tagline">DEKAT ENGINEERING</p>
          <div className="social-media-icons">
            <a
              href="https://www.youtube.com/@dekatengineering8335"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtubeLogo} alt="YouTube" />
            </a>
            <a
              href="https://www.linkedin.com/company/dekat-engineering/"
              className="social-icon"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinLogo} alt="LinkedIn" />
            </a>
          </div>
        </div>
        <div className="footer-column address">
          <h3>Address</h3>
          <p>
            3333 S Bannock St. Suite 750
            <br />
            Englewood, CO 80110
          </p>
          <p>Ph: (720) 918-6125</p>
          <p>
            <a href="mailto:info@dekatengineering.com">
              info@dekatengineering.com
            </a>
          </p>
        </div>
        <div className="footer-column quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <Link to="/home">Home</Link>
            </li>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/services">Our Services</Link>
            </li>
            <li></li>
            <li>
              <Link to="/projects">Career</Link>
            </li>
            <li>
              <Link to="/forms">Forms</Link>
            </li>
            <li>
              <Link to="/team">Meet Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
