import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";

import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Portfolio from "./components/Portfolio/Portfolio";
import Services from "./components/Services/Services";
import Team from "./components/Team/Team";
import Career from "./components/Career/Career";
import Blog from "./components/Blog/Blog";
import Forms from "./components/Forms/Forms";
import JobDetails from "./components/Career/JobDetails";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career" element={<Career />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/team" element={<Team />} />
        <Route path="/forms" element={<Forms />} />
        <Route path="/jobs/:jobId" element={<JobDetails />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
