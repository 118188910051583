import React, { useEffect } from "react";
import image from "../../assets/steel-bridge-2.jpg";
import banner from "../../assets/banner_img1.jpg";
import "./About.scss";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { ref: bannerRef, inView: bannerInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const bannerControls = useAnimation();

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const textControls = useAnimation();

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const imageControls = useAnimation();

  const { ref: footerRef, inView: footerInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const footerControls = useAnimation();

  const { ref: valuesRef, inView: valuesInView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  const valuesControls = useAnimation();

  useEffect(() => {
    if (textInView) textControls.start("visible");
    if (imageInView) imageControls.start("visible");
    if (footerInView) footerControls.start("visible");
    if (valuesInView) valuesControls.start("visible");
  }, [
    bannerInView,
    textInView,
    imageInView,
    footerInView,
    valuesInView,
    bannerControls,
    textControls,
    imageControls,
    footerControls,
    valuesControls,
  ]);

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className="about-container">
      <div className="banner-container">
        <img src={banner} alt="About Us Banner" />
        <div className="banner-text">About Us</div>
      </div>
      <motion.div
        className="about-text"
        ref={textRef}
        initial="hidden"
        animate={textControls}
        variants={fadeInUp}
      >
        <p>
          DEKAT Engineering is founded by like-minded engineers who view the
          engineering profession as both an art and a science. We share a
          passion for innovation and a deep appreciation for the impact of our
          profession on society. For us, engineering is not merely a job; it's a
          calling- an opportunity to leave a lasting imprint on the world by
          designing solutions that improves the lives of people.
        </p>
        <p>
          As stewards of the built environment, we are committed to ethical and
          sustainable practices that minimize our impact on the planet and
          promote the well-being of future generations.
        </p>
        <p>
          Founders of DEKAT participated in the 2019 regional AISC Steel Bridge
          Competition.
        </p>
        <ul>
          <li>Placed first in the state of Colorado, Category “Overall”</li>
          <li>Placed third in the Rocky Mountain Region, Category “Overall”</li>
          <li>Placed second in the Nationals, Category “Aesthetics”</li>
        </ul>
        <button className="learn-about"
          onClick={() =>
            window.open(
              "https://ucdengineeringnews.com/2019/04/09/steel-bridge-team-places-first-in-state-third-in-region/",
              "_blank"
            )
          }
        >
          <span className="span-learn"> Learn More</span>
         
        </button>
      </motion.div>
      <motion.div
        className="about-image"
        ref={imageRef}
        initial="hidden"
        animate={imageControls}
        variants={fadeInUp}
      >
        <img src={image} alt="About Us" />
      </motion.div>
      <motion.div
        className="about-footer"
        ref={footerRef}
        initial="hidden"
        animate={footerControls}
        variants={fadeInUp}
      >
        <h1>Our Mission</h1>
        <p>
          “At DEKAT, our mission is to pioneer innovative engineering solutions
          that transcend boundaries and inspire progress. Our founders come from
          diverse backgrounds and experiences, and with roots in both Nepal and
          the US, we aim to bridge gaps, transfer engineering knowledge and
          empower communities worldwide to access and benefit from the latest
          advancements in engineering and innovations.”
        </p>
      </motion.div>
      <motion.div
        className="about-values"
        ref={valuesRef}
        initial="hidden"
        animate={valuesControls}
        variants={fadeInUp}
      >
        <h2>Core Values</h2>
        <p className="about-values-text">
          We consistently uphold integrity by owning our work, prioritizing the
          well-being of our team, and maintaining a steadfast commitment to
          safety and sustainability. We allocate resources to benefit our
          clients, our team members, and the communities we serve, fostering
          mutual growth and progress.
        </p>
        <div className="values-list">
          <div className="value">
            <h3>
              <span className="first-letter">D</span>edication
            </h3>
            <p>
              Dedication is the foundation of our company's core values. It
              guides us in our relentless pursuit of excellence, safety, and
              quality in all that we do.
            </p>
          </div>
          <div className="value">
            <h3>
              <span className="first-letter">E</span>mpowerment
            </h3>
            <p>
              At DEKAT, we encourage every team member to take ownership of
              their work, share ideas, and actively participate in the
              decision-making processes. We believe that excellence can only be
              attained through empowerment.
            </p>
          </div>
          <div className="value">
            <h3>
              <span className="first-letter">K</span>aizen
            </h3>
            <p>
              Kaizen, aka, continuous improvement, lies at the heart of our
              company's ethos. We recognize that small incremental changes, when
              accumulated over time, can lead to significant improvements. At
              DEKAT, we understand there is always room for improvement, and we
              actively see out every opportunity to enhance our processes,
              systems, and skills set.
            </p>
          </div>
          <div className="value">
            <h3>
              <span className="first-letter">A</span>daptability
            </h3>
            <p>
              Change is inevitable, and it is our response to these challenges
              that defines us. AT DEKAT, we are pro-actively working to make
              changes to our processes and strategies that benefit our team,
              client and company.
            </p>
          </div>
          <div className="value">
            <h3>
              <span className="first-letter">T</span>eamwork
            </h3>
            <p>
              At DEKAT, teamwork is not just a concept but a guiding principle
              that permeates every aspect of our work. We cultivate a culture
              where collaboration is not only encouraged but celebrated. We
              truly believe that teamwork and collaboration leverage the
              strengths of each individual to create something greater than the
              sum of its parts.
            </p>
          </div>
        </div>
      </motion.div>
      {/* <Services /> */}
    </div>
  );
};

export default About;
